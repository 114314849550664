// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tags-list-js": () => import("/opt/build/repo/src/templates/TagsList.js" /* webpackChunkName: "component---src-templates-tags-list-js" */),
  "component---src-templates-series-list-js": () => import("/opt/build/repo/src/templates/SeriesList.js" /* webpackChunkName: "component---src-templates-series-list-js" */),
  "component---src-templates-series-entry-js": () => import("/opt/build/repo/src/templates/SeriesEntry.js" /* webpackChunkName: "component---src-templates-series-entry-js" */),
  "component---src-templates-blog-entry-js": () => import("/opt/build/repo/src/templates/BlogEntry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-tags-js": () => import("/opt/build/repo/src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-contacto-exito-js": () => import("/opt/build/repo/src/pages/contacto-exito.js" /* webpackChunkName: "component---src-pages-contacto-exito-js" */),
  "component---src-pages-contacto-js": () => import("/opt/build/repo/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-series-js": () => import("/opt/build/repo/src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */)
}

