module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145300876-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ed1250","showSpinner":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Overpass Mono:400,700","Roboto:400,500&display=swap"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.21","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-vscode","id":"1042f523-a7d3-57f8-b422-a2ecd9a51e85","name":"gatsby-remark-vscode","version":"1.2.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"enableCustomId":true,"removeAccents":true}},{"resolve":"gatsby-remark-vscode","options":{"colorTheme":"Horizon Bold","extensions":[{"identifier":"jolaleye.horizon-theme-vscode","version":"2.0.2"}],"extensionDataDirectory":"/opt/build/repo/.vscode-extensions"}},"gatsby-remark-relative-images",{"resolve":"gatsby-remark-external-links","options":{"target":"_self","rel":"nofollow"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"linkImagesToOriginal":false,"withWebp":true,"traceSVG":{"color":"transparent","background":"#535c81"}}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://taverasmisael.com"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
